import type { ActionArgs, LoaderArgs, MetaFunction } from "@remix-run/node";
import { Form, Link, useActionData, useTransition } from "@remix-run/react";
import { Button, Checkbox, Input, Space, Typography } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import * as React from "react";
import invariant from "tiny-invariant";
import InputPassword from "~/components/signup/input-password";

import { getUnredactedUserByEmail, isUserAnAdmin } from "~/models/user.server";
import { badRequest } from "~/utils/request.server";
import {
  createSession,
  requireNoUser,
  verifyPassword,
} from "~/utils/session.server";
import {
  isTransitioning,
  isValidEmail,
  safeRedirect,
  validateRequiredStringField,
} from "~/utils/utils";

const { Text, Title } = Typography;

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "Login",
  viewport: "width=device-width,initial-scale=1",
  description:
    "Sign into your Atheva account through Atheva's login page. No login? Join as a buyer or seller for free.",
});

export async function loader({ request }: LoaderArgs) {
  await requireNoUser(request);
  return null;
}

export async function action({ request }: ActionArgs) {
  const formData = await request.formData();
  const email = formData.get("email");
  const password = formData.get("password");
  const remember = formData.get("remember");
  const fieldErrors = {
    email: !isValidEmail(email) ? "Email is invalid" : null,
    password: validateRequiredStringField(password, "Password"),
    // Don't validate password like we do during signup, because our rules could evolve
  };

  if (Object.values(fieldErrors).some(Boolean)) {
    return badRequest({
      fieldErrors,
      formError: null,
    });
  }

  invariant(typeof email === "string");
  invariant(typeof password === "string");

  const user = await getUnredactedUserByEmail(email);
  if (!user) {
    // No user found. Don't reveal its absence
    return badRequest({
      fieldErrors: null,
      formError: "Invalid email or password",
    });
  }

  if (!(await verifyPassword(user, password)))
    return badRequest({
      fieldErrors: null,
      formError: "Invalid email or password",
    });

  const redirectTo = safeRedirect(
    formData.get("redirectTo") || (isUserAnAdmin(user) ? "/admin" : "/")
  );
  return createSession({
    redirectTo,
    remember: remember === "on",
    userId: user.id,
  });
}

export default function Login() {
  const actionData = useActionData<typeof action>();
  const fieldErrors: any = actionData?.fieldErrors ?? {};
  const transition = useTransition();
  const [remember, setRemember] = React.useState("on");

  const onChangeRemember = (e: CheckboxChangeEvent) => {
    setRemember(e.target.checked ? "on" : "");
  };

  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      <div className="Auth-container Auth-bg-image">
        <div className="Auth-content u-bg-white u-pad-40--mobile-20">
          <Space
            className="u-max-width-400"
            direction="vertical"
            size="large"
            style={{ display: "flex" }}
          >
            <Space
              className="u-text-center"
              direction="vertical"
              size="large"
              style={{ display: "flex" }}
            >
              <Title level={1} style={{ lineHeight: "1.35", fontSize: "30px" }}>
                Login
              </Title>
              <Text>Welcome back</Text>
            </Space>

            <Form method="post" noValidate>
              <Space
                direction="vertical"
                size="large"
                style={{ display: "flex" }}
              >
                <Space direction="vertical" style={{ display: "flex" }}>
                  <Title level={5}>
                    <label htmlFor="email">Email address</label>
                  </Title>
                  <Input
                    autoFocus
                    id="email"
                    name="email"
                    placeholder="name@domain.com"
                    type="email"
                  />
                  {fieldErrors?.email && (
                    <div className="u-error">{fieldErrors.email}</div>
                  )}
                </Space>

                <Space direction="vertical" style={{ display: "flex" }}>
                  <Title level={5}>
                    <label htmlFor="password">Password</label>
                  </Title>
                  <InputPassword placeholder="Enter your password" />
                  {fieldErrors?.password && (
                    <div className="u-error">{fieldErrors.password}</div>
                  )}
                  <Link to="/recovery">Forgot your password?</Link>
                </Space>

                <div>
                  <Checkbox
                    defaultChecked={remember === "on"}
                    id="remember"
                    onChange={onChangeRemember}
                  >
                    <label htmlFor="remember">
                      Keep me logged in on this device
                    </label>
                  </Checkbox>
                  <input type="hidden" name="remember" value={remember} />
                </div>

                {actionData?.formError && (
                  <div className="u-error">{actionData.formError}</div>
                )}

                <div style={{ textAlign: "center" }}>
                  <Button
                    disabled={isTransitioning(transition)}
                    htmlType="submit"
                    size="large"
                    type="primary"
                  >
                    {isTransitioning(transition) ? "Please wait..." : "Log in"}
                  </Button>
                </div>
              </Space>
            </Form>
          </Space>
        </div>
      </div>

      <div className="Auth-container">
        <Text className="Auth-content u-text-center">
          Don't have an account? <Link to="/signup">Sign up</Link>
        </Text>
      </div>
    </Space>
  );
}
